import React, { useEffect } from 'react'
import Spacer from "../components/spacer"
import Layout from "../components/layout"
import HeroBanner from "../components/heroBanner"
import Introduction from "../components/introduction"
import PastProjects from "../components/pastProjects"
import Testimonial from "../components/testimonial"
import Projects from "../components/projects"
import Contact from "../components/contact"
import Popup from "../components/popup"
import Team from "../components/team"
import Cta from "../components/cta"
import Seo from "../components/seo"
import { graphql } from "gatsby" 

const IndexPage = ({data}) => {
  var popup = data.popup;
  var page = data.page;
  useEffect(() => {
     document.querySelector('body').classList.add('loaded');
  });
  return ( 
  <Layout>
    <Seo title="Walt Construction | Noosa Builder | New Homes & Renovations" />
    <Popup popup={popup} />
    <HeroBanner title={page.title} image={page.heroImage} />
    <Spacer />
    <Introduction title={page.introTitle} subtitle={page.introSubtitle} content={page.introContent} />
    <Spacer className='m-hide' />
    <Spacer />
    <Projects title={page.projectsTitle} subtitle={page.projectsSubtitle} projects={data.projects.edges} />
    <Spacer />
    <Team team={data.team.edges} />
    <Spacer />
    <Testimonial title={page.testimonialTitle} image={page.testimonialImage} content={page.testimonialContent} client={page.testimonialClient} build={page.testimonialBuild} />
    <Spacer />
    <PastProjects title='Past Projects' projects={page.pastProjects} />
    <Spacer />
    <Spacer className='m-hide' />
    <Cta title={page.ctaTitle} subtitle={page.ctaSubtitle} gallery={page.ctaGallery} />
    <Spacer className='m-hide' />
    <Spacer />
    <Contact phone={page.phone} email={page.email} subtitle={page.contactTitle} />
    <Spacer />
  </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Homepage {
    popup:datoCmsPopup {
      title
      content
      image {
        gatsbyImageData
      }
    }
    page:datoCmsWaltConstructionNew {
      title {
        title
      }
      heroImage {
        gatsbyImageData
      }
      introTitle
      introSubtitle
      introContent
      projectsTitle
      projectsSubtitle
      pastProjects {
        title
        subtitle
        link
        image {
          gatsbyImageData
        }
      }
      testimonialClient
      testimonialContent
      testimonialTitle
      testimonialBuild
      testimonialImage {
        gatsbyImageData
      }
      ctaTitle
      ctaSubtitle
      ctaGallery {
        gatsbyImageData
      }
      contactTitle
      phone
      email
    } 
    projects:allDatoCmsRecentProject(sort: {fields: position, order: ASC}) {
      edges {
        node {
          id
          name
          subtitle
          heroImage {
            gatsbyImageData
          }

        }
      }
    }
    team:allDatoCmsTeam(sort: {fields: position, order: ASC}) {
      edges {
        node {
          id
          name
          jobPosition
          image {
            gatsbyImageData
          }

        }
      }
    }
  }
`