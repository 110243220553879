import React, { useEffect } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import Form from "../components/popupForm"

const Popup = ({popup}) => {
  const closePopup = () => {
    document.body.classList.remove('popup-open');
  }
  const openForm = () => {
    document.querySelector('.popup--modal').classList.add('active');
  }
  useEffect(() => {
    setTimeout(() => {
      document.body.classList.add('popup-open');
    }, 5000);
  });
  return (
  <>
  <div className='popup--modal'>
    <div className='close-popup' onClick={closePopup} role='presentation'/>
     
    <div className='image-wrapper pos-rel'>
      <GatsbyImage className='bg-image' image={popup.image?.gatsbyImageData} alt='About Image'  />
    </div>
     
    <div className=''>
      <div className='p40'>
        <div className='popup--content'>
          <p className='h3 m0 mb20 title white-space'>{popup.title}</p>
          <div className='lh2 grey content mb40 white-space with-highlight m-mr20' dangerouslySetInnerHTML={{ __html: popup.content }} />
          <p className='button block m0 m-mb20' onClick={openForm} role='presentation'>Join The Waitlist</p>
        </div>
        <div className='form'>
          <Form />
        </div>
      </div>  
    </div>
  </div>
  <div className='popup--bg' onClick={closePopup} role='presentation'/>
  </>
  )
}

export default Popup
 